<template>
	<div class="container">

		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item active">
					<router-link :to="{name: 'projects-index'}">Projects</router-link>
				</li>
			</ol>
		</nav>

		<div>

			<table class="table">
				<thead>
				<tr>
					<td>Code</td>
					<td>Name</td>
					<td></td>
				</tr>
				</thead>
				<tbody>
				<tr v-for="(project, index) in projects" :key="project.Uuid">
					<td>
						<inline-editor :disabled="!canWrite('projects')" @update="updateProject(index, project)"
									   v-model="project.Code"
									   placeholder="Project name"></inline-editor>
					</td>
					<td>
						<inline-editor :disabled="!canWrite('projects')" @update="updateProject(index, project)"
									   v-model="project.Name"
									   placeholder="Project name"></inline-editor>
					</td>
					<td>
						<router-link v-if="canRead('projects_config')" class="btn btn-link"
									 :to="{name: 'projects-editor-uuid-index', params: {uuid: project.Uuid}}"
									 :key="project.Uuid" title="Configurations">
							<i class="fa fa-cog"></i>
						</router-link>
						<button class="btn btn-link text-danger" @click="deleteProject(project)" title="Delete"
								v-if="canWrite('projects')">
							<i class="fa fa-trash-alt"></i>
						</button>
					</td>
				</tr>
				</tbody>
			</table>

			<router-link :to="{name: 'projects-editor-new'}" class="btn btn-primary" v-if="canWrite('projects')">
				Create new project
			</router-link>
		</div>
	</div>
</template>

<script>
	import api from '@/api/'
	import InlineEditor from "../../components/ui/inline-editor";
	import messageBox from '@/components/modals/confirm'

	export default {
		components: {InlineEditor},
		computed: {
			projects: {
				get() {
					return this.$store.getters["Projects/list"]
				},
				set(projects) {
					this.$store.commit('Projects/setProjects', projects)
				}
			},
		},
		data() {
			return {
				columns: [
					{
						label: 'Code',
						field: 'Code',
					},
					{
						label: 'Project',
						field: 'Name',
					},
					{
						label: 'Last update',
						field: 'UpdatedAt',
					}
				],
			}
		},
		methods: {
			deleteProject(project) {
				messageBox({
					message: `Want to delete project ${project.Code} - ${project.Name}?`
				})
						.then(_ => {
							messageBox({
								message: `SURE SURE SURE?????`
							}).then(_ => {
								api.Projects.deleteProject(project.Uuid)
							})
						})


			},
			updateProject(index, project) {
				api.Projects.updateProject(project.Uuid, {
					Name: project.Name,
					Code: project.Code,
				})
			}
		}
	}
</script>
