<template>
	<vue-inline-text-editor :disabled="disabled" @update="onUpdate"
							:value.sync="text"
							:placeholder="placeholder">
		<span slot="edit-label" class="fa fa-pen"></span>
		<span slot="confirm-label" class=" fa fa-save"></span>
		<span slot="cancel-label" class=" fa fa-ban"></span>
	</vue-inline-text-editor>
</template>

<script>
	import VueInlineTextEditor from 'vue-inline-text-editor'

	export default {
		props: {
			value: {
				type: String,
				default: ""
			},
			disabled: {
				type: Boolean,
				default: false
			},
			placeholder: {},
		},
		data() {
			return {
				localText: null
			}
		},
		created() {
			this.localText = this.value
		},
		methods: {
			onUpdate() {
				this.$emit("update", this.text)
			}
		},
		watch: {
			value(v) {
				this.localText = v
			}
		},
		computed: {
			text: {
				get() {
					return this.value
				},
				set(text) {
					this.$emit('input', text)
				}
			}
		},
		components: {
			VueInlineTextEditor
		}
	}
</script>
